import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../layouts'
import Header from '../components/Header/subpage'

const NotFoundPage = ({ data }) => {
  const headerImage = data.wordpressAcfOptions.options.header_image.localFile.childImageSharp.fluid
  return (
    <Layout
      title="404 Page Not Found"
      seo={null}
      padding="80vh 0 0 0"
      nofooter
    >
      <article>
        <Header
          image={headerImage}
          title="404 Page Not Found"
          text="It looks like nothing was found at this location."
          returnHome
        />
      </article>
    </Layout>
  )
}

export default NotFoundPage

export const NotFoundPageQuery = graphql`
  query NotFoundHeader {
    wordpressAcfOptions {
      options {
        header_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920, maxHeight: 1080) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
